<template>
  <div class="content-wrapper">
    <PageHeader screenName="Ver jobs com falha" :linkItems="linkItems" />

    <main class="card">
      <validation-observer ref="itemRules">
        <b-form>
          <b-row class="pt-1 px-2">
            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="Queue *"
                label-for="queue-input"
                label-class="font_size_label_failed_job"
              >
                <validation-provider
                  #default="{ errors }"
                  name="queue"
                  rules="required"
                >
                  <v-select
                    id="queue"
                    v-model="filter.queue"
                    :options="optionsQueue"
                    required
                    placeholder="Selecione a fila"
                  >
                  </v-select>
                </validation-provider>

                <small v-if="documentoError" class="text-danger">
                  {{ documentoError }}
                </small>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="Job name"
                label-for="jobname-input"
                label-class="font_size_label_failed_job"
              >
                <b-form-input
                  id="jobname-input"
                  v-model="filter.jobname"
                  placeholder="Preencha o nome do job"
                />
              </b-form-group>
            </b-col>

            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="Exception"
                label-for="exception-input"
                label-class="font_size_label_failed_job"
              >
                <b-form-input
                  id="exception-input"
                  v-model="filter.exception"
                  placeholder="Preencha o texto do exceção"
                />
              </b-form-group>
            </b-col>

            <b-col lg="4" md="6" sm="7">
              <b-button @click="filtrarFailedJobs()" variant="primary-button">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Pesquisar</span>
              </b-button>

              <b-button
                type="reset"
                class="ml-2 cor_botao"
                @click="limparPesquisa()"
                variant="outline-primary"
              >
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Limpar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-row class="pt-1 px-2">
        <b-col class="py-1" sm="12" lg="12">
          <div
            v-if="!this.filtering"
            class="d-flex justify-center-center align-items-center"
          >
            <span class="mr-50">Mostrar</span>
            <v-select
              id="orders"
              v-model="paginationData.defaultSize"
              :options="table.optionsSize"
              :clearable="false"
              @input="updateQtdView($event)"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </div>
        </b-col>

        <b-table
          id="listFailedJobTable"
          responsive
          sticky-header="100%"
          :no-local-sorting="true"
          :fields="getFieldsTable"
          :items="table.items"
          show-empty
          @sort-changed="ordemColumn"
          :no-sort-reset="true"
        >
          <!-- Render this if no tabs -->
          <template #empty>
            <span v-if="filtering">Processando.</span>
            <span v-else>Nenhum registro encontrado.</span>
          </template>

          <template #cell(failed_at)="row">
            <span v-text="formatFailedAt(row.item.failed_at)"></span>
          </template>

          <template #cell(jobname)="row">
            <span v-text="jobName(row.item.payload.data.commandName)"></span>
          </template>

          <template #cell(payload)="row">
            <a
              href="#"
              @click="
                mostrarInformacaoDetalhada(
                  row.item.payload,
                  `Payload:`,
                  row.item.uuid
                )
              "
              >Ver detalhado</a
            >
          </template>
          <template #cell(exception)="row">
            <a
              href="#"
              @click="
                mostrarInformacaoDetalhada(
                  row.item.exception,
                  `Exception:`,
                  row.item.uuid
                )
              "
              >Ver detalhado</a
            >
          </template>

          <template #cell(actions)="row">
            <span @click="reprocessarJobComFalhas(row.item.id)">
              <button-icon
                color="#2772C0"
                size="18"
                feather-icon="RepeatIcon"
              />
            </span>

            <span @click="excluirJobComFalhas(row.item.id)">
              <button-icon
                ccolor="#2772C0"
                size="18"
                feather-icon="Trash2Icon"
              />
            </span>
          </template>
        </b-table>
        <div class="pb-2 px-1 w-100" v-if="table.items.length > 0">
          <b-col sm="12" class="mt-2">
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </b-row>
    </main>
    <b-modal id="modal-descricao-detalhada" size="xl" :title="modal.title">
      <pre v-html="modal.description"></pre>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BModal,
} from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  title: "Jobs com falha",

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BModal,
    PageHeader,
    CustomPagination,
    ButtonIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      filtering: false,
      linkItems: [
        {
          name: "Suporte",
          routeName: "",
        },
        {
          name: "Ver jobs com falha",
          active: true,
        },
      ],
      table: {
        fields: [
          { key: "failed_at", sortable: true, label: "failed_at" },
          { key: "uuid", sortable: true, label: "uuid" },
          { key: "queue", sortable: true, label: "queue" },
          { key: "jobname", sortable: false, label: "job_name" },
          { key: "payload", sortable: false, label: "payload" },
          { key: "exception", sortable: false, label: "exception" },
          {
            key: "actions",
            label: "ações",
            sortable: false,
            class: "mw-150 text-center col-1",
            visible: true,
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: "id",
        order: "desc",
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      modal: {
        title: "",
        description: "",
      },
      documentoError: "",
      filter: {
        queue: "",
        jobname: "",
        exception: "",
      },
      optionsQueue: [
        { id: "adesao", label: "Adesão" },
        { id: "default", label: "Default" },
        { id: "email", label: "Email" },
        { id: "importacao", label: "Importação" },
        { id: "importarvacinados", label: "Importação de Vacinados" },
        { id: "limpeza", label: "Limpeza" },
        { id: "notificacao", label: "Notificação" },
        { id: "relatorio", label: "Relatório" },
      ],
    };
  },

  mounted() {
    this.buscarJobsComFalhas();
  },

  methods: {
    mostrarModalReprocessamento(sucesso, text = "") {
      let title = sucesso ? "Reprocessado com sucesso" : "Erro ao reprocessar";

      this.$swal({
        title: `<h3>${title}</h3>`,
        text: `${text}`,
        icon: sucesso ? "success" : "error",
        iconColor: sucesso ? "#36ba00" : "red",
        customClass: {
          confirmButton: "btn btn-custom-blue",
        },
        buttonsStyling: false,
      });
    },

    mostrarModalExclusao(sucesso, text = "") {
      let title = sucesso ? "Excluído com sucesso" : "Erro ao excluir";

      this.$swal({
        title: `<h3>${title}</h3>`,
        text: `${text}`,
        icon: sucesso ? "success" : "error",
        iconColor: sucesso ? "#36ba00" : "red",
        customClass: {
          confirmButton: "btn btn-custom-blue",
        },
        buttonsStyling: false,
      });
    },

    buscarJobsComFalhas() {
      this.table.items = [];
      this.$http
        .get(this.$api.getJobsComFalha(), {
          params: this.setParams(),
        })
        .then((response) => {
          let items = [];

          if (response.data.data.length > 0) {
            items = response.data.data.map((item) => {
              let payload = JSON.parse(item.payload);
              payload.data.command = this.$helpers.phpUnserialize(
                payload.data.command
              );
              item.payload = payload;
              return item;
            });
          }

          this.table.items = items;
          this.startPagination(response.data);
          this.filtering = false;
        });
    },

    reprocessarJobComFalhas(idFailedJob) {
      this.$http
        .post(this.$api.jobComFalhaReprocessar(idFailedJob))
        .then((response) => {
          this.mostrarModalReprocessamento(true, response.data.mensagem);
          this.buscarJobsComFalhas();
        })
        .catch(({ response: { data } }) => {
          this.mostrarModalReprocessamento(false, data.errors);
        });
    },

    excluirJobComFalhas(idFailedJob) {
      const vm = this;
      this.$swal({
        title: "Exclusão do job?",
        text: "Deseja realmente excluir o job?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "red",
        confirmButtonText: "Sim",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(this.$api.jobComFalhaExcluir(idFailedJob))
            .then((response) => {
              vm.mostrarModalExclusao(true, response.data.mensagem);
              vm.buscarJobsComFalhas();
            })
            .catch(({ response: { data } }) => {
              vm.mostrarModalExclusao(false, data.errors);
            });
        }
      });
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    ordemColumn(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? "desc" : "asc";
      this.filtering = true;
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }

      this.paginationData.currentPage = 1;
      this.filtering = true;
    },

    mostrarInformacaoDetalhada(description, title, uuid) {
      title += ` ${uuid}`;
      this.modal = {
        description,
        title,
      };
      this.$bvModal.show("modal-descricao-detalhada");
    },

    setParams() {
      return {
        columnName: this.table.columnOrder,
        columnOrder: this.table.order,
        perPage: this.paginationData.defaultSize,
        page: this.paginationData.currentPage,
        queue: this.filter.queue.id,
        jobname: this.filter.jobname,
        exception: this.filter.exception,
      };
    },

    jobName(commandName) {
      return commandName.split(/[\\]+/).pop();
    },

    formatFailedAt(failedAt) {
      return moment(failedAt).format("DD/MM/YYYY HH:mm:ss");
    },

    limparPesquisa() {
      this.documentoError = "";
      this.paginationData.currentPage = 1;
      this.filter = {
        queue: "",
        jobname: "",
        exception: "",
      };
      this.filtering = true;
    },

    filtrarFailedJobs() {
      this.$refs.itemRules.validate().then((success) => {
        this.documentoError =
          this.filter.queue.length == 0
            ? "Este campo é de preenchimento obrigatório."
            : "";

        if (success) {
          this.paginationData.currentPage = 1;
          this.filtering = true;
        }
      });
    },
  },

  computed: {
    getFieldsTable() {
      return this.table.fields;
    },
  },
  watch: {
    filtering: {
      immediate: true,
      handler() {
        if (this.filtering) {
          this.buscarJobsComFalhas();
        }
      },
    },
  },
};
</script>
